import { ChangeEvent, forwardRef, memo, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import TextareaAutosize from 'react-textarea-autosize';
import { getCssVar } from '../../utils';
import { IconLabel } from '../IconLabel';

import classNames from 'classnames';
import classes from './TextAreaField.module.scss';

interface TextAreaFieldProps {
  value: string;
  label?: string;
  onChange?: (event: ChangeEvent<HTMLTextAreaElement>) => void;
  onKeyDown?: (event: React.KeyboardEvent<HTMLTextAreaElement>) => void;
  onBlur?: (event: React.FocusEvent<HTMLTextAreaElement>) => void;
  inputClassName?: string;
  placeholder?: string;
  disableLineBreak?: boolean;
  minLength?: number;
  maxLength?: number;
  disabled?: boolean;
  hidden?: boolean;
  readonly?: boolean;
  minRows?: number;
  autoFocus?: boolean;
}

export const TextAreaField = memo(
  forwardRef<HTMLTextAreaElement, TextAreaFieldProps>(
    (
      {
        value,
        label,
        onChange,
        onKeyDown,
        onBlur,
        inputClassName,
        placeholder,
        disableLineBreak,
        minLength,
        maxLength,
        disabled,
        hidden,
        readonly,
        minRows,
        autoFocus,
      },
      ref
    ) => {
      const { t } = useTranslation();

      const [showCharsCounter, setShowCharsCounter] = useState(false);

      const [minLengthError, setMinLengthError] = useState(false);

      const renderError = useMemo(() => {
        if (!minLengthError) {
          return null;
        }

        return (
          <>
            <IconLabel
              className={classNames(classes['text-area-field__error-icon'], {
                [classes['text-area-field__error-icon--without-label']]: !label,
              })}
              iconId={'alert'}
              iconSize={18}
              color={getCssVar('--input-icon-error-color')}
              singleColor
              nonClickable
            />

            {minLengthError && (
              <div className={classes['text-area-field__error']}>
                {t('common.input-min-length-error', { count: minLength })}
              </div>
            )}
          </>
        );
      }, [label, minLength, minLengthError, t]);

      const onKeyDownHandler = useCallback(
        (event: React.KeyboardEvent<HTMLTextAreaElement>) => {
          const { key } = event;

          if (disableLineBreak && key === 'Enter') {
            event.preventDefault();
          }

          onKeyDown?.(event);
        },
        [disableLineBreak, onKeyDown]
      );

      const onBlurHandler = useCallback(
        (event: React.FocusEvent<HTMLTextAreaElement, Element>) => {
          if (minLength && value.length < minLength) {
            setMinLengthError(true);
          }

          setShowCharsCounter(false);
          onBlur?.(event);
        },
        [minLength, onBlur, value.length]
      );

      const onFocusHandler = useCallback(() => {
        if (minLengthError) {
          setMinLengthError(false);
        }

        setShowCharsCounter(true);
      }, [minLengthError]);

      return (
        <div
          className={classNames(classes['text-area-field'], {
            [classes['text-area-field--hidden']]: hidden,
          })}
        >
          {label && (
            <label className={classes['text-area-field__label']} htmlFor={label}>
              {label}
            </label>
          )}

          <TextareaAutosize
            id={label}
            ref={ref}
            className={classNames(classes['text-area-field__value'], inputClassName, {
              [classes['text-area-field__value--disabled']]: disabled,
              [classes['text-area-field__value--error']]: minLengthError,
            })}
            value={value}
            placeholder={placeholder}
            onFocus={onFocusHandler}
            onBlur={onBlurHandler}
            onKeyDown={onKeyDownHandler}
            onChange={(event) => !disabled && onChange?.(event)}
            minLength={minLength}
            maxLength={maxLength}
            readOnly={readonly}
            spellCheck={false}
            minRows={minRows}
            autoFocus={autoFocus}
          />

          {!readonly && maxLength && (
            <div className={classes['text-area-field__counter']}>
              {showCharsCounter && (
                <>
                  {value?.length} / {maxLength}
                </>
              )}
              &nbsp;
            </div>
          )}

          {renderError}
        </div>
      );
    }
  )
);
