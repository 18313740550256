import { ImageFile, isAdminLayout, StatusCopyright } from '../shared';
import { graphqlApi } from './graphqlApi';
import { optional, queryfy, storyQuery } from './helpers';
import { OrganisationTag } from './organisation';
import { User } from './user';

export enum StoryStatus {
  PUBLISHED = 'PUBLISHED',
  UNPUBLISHED = 'UNPUBLISHED',
  SCHEDULED = 'SCHEDULED',
}

export enum StorySection {
  WEB_FEED = 'WEB_FEED',
  MOBILE_FEED = 'MOBILE_FEED',
  MIX_LIST = 'MIX_LIST',
  MIX_LIST_2 = 'MIX_LIST_2',
  PINNED = 'PINNED',
  PINNED_ORG = 'PINNED_ORG', // * custom enum value
  PINNED_CHANNEL = 'PINNED_CHANNEL', // * custom enum value
  HORIZONTAL = 'HORIZONTAL',
  UNASSIGNED = 'UNASSIGNED',
}

export enum StoryAccess {
  EDITOR_LIMITED = 'EDITOR_LIMITED',
  READER = 'READER',
}

export enum StoryType {
  READONLY = 'READONLY',
  STANDARD = 'STANDARD',
}

export enum StoryPinned {
  CHANNEL = 'CHANNEL',
  ORGANISATION = 'ORGANISATION',
}

export enum StoryHighlightingCommentsMode {
  FIRST = 'FIRST',
  MANUAL = 'MANUAL',
  MOST_LIKEABLE = 'MOST_LIKEABLE',
}

export enum StoryCardTeaserImageStyle {
  BIG_WITHOUT_TEXT = 'BIG_WITHOUT_TEXT',
  SMALL_WITHOUT_TEXT = 'SMALL_WITHOUT_TEXT',
  SMALL_WITH_TEXT = 'SMALL_WITH_TEXT',
  STANDARD = 'STANDARD',
}

export enum StoryDisplayItemUpdatedTime {
  POSTED_TIME = 'POSTED_TIME',
  UPDATED = 'UPDATED',
}

export interface StoryHorizontalDisplayView {
  position: number;
}

export enum RestrictDuplicateUrlOfCardsInTimeFrame {
  HALF_DAY = 'HALF_DAY',
  NEVER = 'NEVER',
  ONE_DAY = 'ONE_DAY',
  ONE_HOUR = 'ONE_HOUR',
  ONE_WEEK = 'ONE_WEEK',
  SIX_HOURS = 'SIX_HOURS',
  THREE_DAYS = 'THREE_DAYS',
  THREE_HOURS = 'THREE_HOURS',
  TWO_DAYS = 'TWO_DAYS',
}

export enum StoryCardLimit {
  ONE_THOUSAND = 'ONE_THOUSAND',
  TWO_THOUSANDS = 'TWO_THOUSANDS',
  FIVE_THOUSANDS = 'FIVE_THOUSANDS',
  TEN_THOUSANDS = 'TEN_THOUSANDS',
  TWENTY_THOUSANDS = 'TWENTY_THOUSANDS',
  UNLIMITED = 'UNLIMITED',
}

export interface StorySettings {
  access: StoryAccess[];
  allowAccessToEditorLimited: boolean;
  allowAccessToReader: boolean;
  allowCardReactions: boolean;
  allowComments: boolean;
  allowSharing: boolean;
  horizontalDisplayView: StoryHorizontalDisplayView;
  includeInMixTab: boolean;
  includeInMixTab2: boolean;
  includeInNewsFeed: boolean;
  includeInWebappNewsFeed: boolean;
  section: StorySection[];
  shouldSyncItemPositionsOnConnectedStories: boolean;
  shouldUpdatePostedTimeByComments: boolean;
  showAllAuthors: boolean;
  cardStyles: {
    article: {
      teaserStyle: StoryCardTeaserImageStyle;
    };
  };
  displayItemUpdatedTime: StoryDisplayItemUpdatedTime;
  highlightingComments: StoryHighlightingCommentsMode;
  shouldRestrictDuplicateUrlOfCardsInTimeFrame: RestrictDuplicateUrlOfCardsInTimeFrame;
  cardLimit: StoryCardLimit;
}

export interface Story {
  author: User;
  authorId: number;
  cardsCount: number;
  channelId: number;
  id: number;
  image: ImageFile;
  imageId: number;
  isReadOnly: boolean;
  localeId: number;
  pinned: StoryPinned;
  position: number;
  settings: StorySettings;
  status: StoryStatus;
  subtitle: string;
  title: string;
  type: StoryType;
  externalTags: OrganisationTag[];
  internalTags?: OrganisationTag[];
}

interface StoriesByChannelIdResponse {
  data: {
    storiesByChannelId: {
      items: Story[];
    };
  };
}

export interface StoriesByChannelIdFilter {
  onlyPostIn?: boolean;
  status?: StoryStatus[];
  section?: StorySection[];
  access?: StoryAccess[];
  type?: StoryType[];
}

export interface StoriesByChannelIdArgs {
  channelId: number;
  filter?: StoriesByChannelIdFilter;
}

interface StoriesByIdResponse {
  data: {
    storyById: {
      payload: Story;
    };
  };
}

interface StoriesByIdArgs {
  storyId: number;
}

interface StoryDeleteResponse {
  data: {
    storyDelete: {
      payload: boolean;
    };
  };
}

interface StoryDeleteArgs {
  storyId: number;
}

export interface StoryUpdateArgs {
  storyId: number;
  fields: Partial<Story>;
}

interface StoryUpdateResponse {
  data: {
    storyUpdate: {
      payload: Story;
    };
  };
}

interface StoryUpdatePositionArgs {
  storyId: number;
  position: number;
}

interface StoryUpdatePositionResponse {
  data: {
    storyUpdatePosition: {
      payload: boolean;
    };
  };
}

interface StoryCreateResponse {
  data: {
    storyCreate: {
      payload: Story;
    };
  };
}

export interface StoryCreateFieldsInput {
  imageId: number | null;
  imageRightholder?: string;
  imageStatusCopyrightId?: StatusCopyright;
  title: string;
  subtitle?: string;
  sourceStoryId?: number;
  status?: StoryStatus;
  sourceStoryFetchLastCards?: number;
  localeId?: number;
  pinned?: StoryPinned;
  settings?: {
    access?: StoryAccess[];
    includeInWebappNewsFeed?: boolean;
    includeInNewsFeed?: boolean;
    includeInMixTab?: boolean;
    includeInMixTab2?: boolean;
    allowComments?: boolean;
    allowSharing?: boolean;
    allowCardReactions?: boolean;
    horizontalDisplayView: StoryHorizontalDisplayView | null;
    highlightingComments?: StoryHighlightingCommentsMode;
    displayItemUpdatedTime?: StoryDisplayItemUpdatedTime;
    shouldSyncItemPositionsOnConnectedStories?: boolean;
    shouldRestrictDuplicateUrlOfCardsInTimeFrame?: RestrictDuplicateUrlOfCardsInTimeFrame;
    shouldUpdatePostedTimeByComments?: boolean;
    cardLimit?: StoryCardLimit;
    showAllAuthors?: boolean;
    cardStyles?: {
      article: {
        teaserStyle: StoryCardTeaserImageStyle;
      };
    };
  };
  organisationTags?: {
    externalTagsId?: number[];
    internalTagsId?: number[];
  };
}

interface StoryCreateArgs {
  channelId: number;
  fields: StoryCreateFieldsInput;
}

interface SourceStoryByStoryIdArgs {
  storyId: number;
}

interface SourceStoryByStoryIdPayload {
  story: {
    id: number;
  };
  channel: {
    subdomain: string;
  };
}

interface SourceStoryByStoryIdResponse {
  data: {
    sourceStoryByStoryId: {
      payload: SourceStoryByStoryIdPayload;
    };
  };
}

const storyEnumObj = {
  status: Object.keys(StoryStatus),
  pinned: Object.keys(StoryPinned),
  access: Object.keys(StoryAccess),
  highlightingComments: Object.keys(StoryHighlightingCommentsMode),
  teaserStyle: Object.keys(StoryCardTeaserImageStyle),
  displayItemUpdatedTime: Object.keys(StoryDisplayItemUpdatedTime),
  shouldRestrictDuplicateUrlOfCardsInTimeFrame: Object.keys(RestrictDuplicateUrlOfCardsInTimeFrame),
  cardLimit: Object.keys(StoryCardLimit),
};

export const storyApi = graphqlApi.injectEndpoints({
  endpoints: (builder) => ({
    getStoryById: builder.query<Story, StoriesByIdArgs>({
      query: ({ storyId }) => ({
        url: '/graphql/webapp?storyById',
        method: 'POST',
        body: {
          query: `
          {
            storyById(
                storyId: ${storyId}
            ) {
              payload {
                ${storyQuery({ isAdminLayout: isAdminLayout() })}
              }
            }
          }`,
        },
      }),
      transformResponse: (response: StoriesByIdResponse) => response.data.storyById.payload,
    }),
    getStoriesByChannelId: builder.query<Story[], StoriesByChannelIdArgs>({
      query: ({ channelId, filter }) => ({
        url: '/graphql/webapp?storiesByChannelId',
        method: 'POST',
        body: {
          query: `
          {
            storiesByChannelId(
                channelId: ${channelId},
                ${
                  filter
                    ? `filter: {
                        ${optional({
                          name: 'onlyPostIn',
                          condition: Boolean(filter.onlyPostIn),
                          value: filter.onlyPostIn,
                        })}
                        ${optional({
                          name: 'status',
                          condition: Boolean(filter.status),
                          value: `[${filter.status}]`,
                        })}
                        ${optional({
                          name: 'section',
                          condition: Boolean(filter.section),
                          value: `[${filter.section}]`,
                        })}
                        ${optional({
                          name: 'access',
                          condition: Boolean(filter.access),
                          value: `[${filter.access}]`,
                        })}
                        ${optional({
                          name: 'type',
                          condition: Boolean(filter.type),
                          value: `[${filter.type}]`,
                        })}
                      }`
                    : ''
                }
            ) {
              items {
                ${storyQuery({ isAdminLayout: isAdminLayout() })}
              }
            }
          }`,
        },
      }),
      forceRefetch: () => true,
      transformResponse: (response: StoriesByChannelIdResponse) =>
        response.data.storiesByChannelId.items,
    }),
    storyDelete: builder.query<boolean, StoryDeleteArgs>({
      query: ({ storyId }) => ({
        url: '/graphql/webapp?storyDelete',
        method: 'POST',
        body: {
          query: `mutation {
            storyDelete(input: { 
              storyId: ${storyId}, 
            }) {
              payload
              error {
                ... on UnknownError {
                  message
                }
              }
            }
          }`,
        },
      }),
      transformResponse: (response: StoryDeleteResponse) => response.data.storyDelete.payload,
    }),
    storyUpdate: builder.query<Story, StoryUpdateArgs>({
      query: ({ storyId, fields }) => ({
        url: '/graphql/webapp?storyUpdate',
        method: 'POST',
        body: {
          query: `mutation {
            storyUpdate(input: { 
              storyId: ${storyId},
              fields: ${queryfy(fields, storyEnumObj)}
            }) {
              payload {
                ${storyQuery({ isAdminLayout: isAdminLayout() })}
              }
              error {
                ... on UnknownError {
                  message
                }
              }
            }
          }`,
        },
      }),
      forceRefetch: () => true,
      transformResponse: (response: StoryUpdateResponse) => response.data.storyUpdate.payload,
    }),
    storyUpdatePosition: builder.query<boolean, StoryUpdatePositionArgs>({
      query: ({ storyId, position }) => ({
        url: '/graphql/webapp?storyUpdatePosition',
        method: 'POST',
        body: {
          query: `mutation {
            storyUpdatePosition(input: { 
              storyId: ${storyId},
              position: ${position}
            }) {
              payload
              error {
                ... on UnknownError {
                  message
                }
              }
            }
          }`,
        },
      }),
      transformResponse: (response: StoryUpdatePositionResponse) =>
        response.data.storyUpdatePosition.payload,
    }),
    storyCreate: builder.query<Story, StoryCreateArgs>({
      query: ({ channelId, fields }) => ({
        url: '/graphql/webapp?storyCreate',
        method: 'POST',
        body: {
          query: `mutation {
            storyCreate(input: { 
              channelId: ${channelId},
              fields: ${queryfy(fields, storyEnumObj)}
            }) {
              payload {
                id
              }
              error {
                ... on UnknownError {
                  message
                }
              }
            }
          }`,
        },
      }),
      transformResponse: (response: StoryCreateResponse) => response.data.storyCreate.payload,
    }),
    sourceStoryByStoryId: builder.query<SourceStoryByStoryIdPayload, SourceStoryByStoryIdArgs>({
      query: ({ storyId }) => ({
        url: '/graphql/webapp?sourceStoryByStoryId',
        method: 'POST',
        body: {
          query: `
          {
            sourceStoryByStoryId(
                storyId: ${storyId}
            ) {
              payload {
                story {
                  id
                }
                channel {
                  subdomain
                }
              }
            }
          }`,
        },
      }),
      transformResponse: (response: SourceStoryByStoryIdResponse) =>
        response.data.sourceStoryByStoryId.payload,
    }),
  }),
});

export const {
  useGetStoryByIdQuery,
  useGetStoriesByChannelIdQuery,
  useStoryDeleteQuery,
  useStoryUpdateQuery,
  useStoryUpdatePositionQuery,
  useStoryCreateQuery,
  useSourceStoryByStoryIdQuery,
} = storyApi;
